import {
  AirportShuttle,
  Autorenew,
  Cancel,
  CheckCircle,
  SvgIconComponent,
} from "@mui/icons-material";
import {
  PayoutInterval,
  PayoutSchedule,
  StripePayoutStatus,
} from "../../../../store/payouts/types";

interface StatusConfig {
  displayName: string;
  color: string;
  icon: SvgIconComponent;
}

export const STRIPE_PAYOUT_STATUSES: Record<StripePayoutStatus, StatusConfig> =
  {
    paid: {
      displayName: "Paid",
      color: "success",
      icon: CheckCircle,
    },
    pending: {
      displayName: "Pending",
      color: "warning",
      icon: Autorenew,
    },
    in_transit: {
      displayName: "In Transit",
      color: "warning",
      icon: AirportShuttle,
    },
    canceled: {
      displayName: "Cancelled",
      color: "error",
      icon: Cancel,
    },
    failed: {
      displayName: "Failed",
      color: "error",
      icon: Cancel,
    },
  };

export const getNextNthDate = (n: number): Date => {
  const currentDate = new Date();
  const next15th = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    n
  );
  if (currentDate.getDate() > n) next15th.setMonth(next15th.getMonth() + 1);
  return next15th;
};

export const getNext15thDate = () => getNextNthDate(15);

const DAYS_OF_WEEK = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

type DayOfWeek = (typeof DAYS_OF_WEEK)[number];

export const getPayoutDate = (
  payoutSchedule: PayoutSchedule | undefined
): Date => {
  if (!payoutSchedule) return getNext15thDate();

  const { interval, monthlyAnchor, weeklyAnchor } = payoutSchedule;

  if (interval === PayoutInterval.MONTHLY && monthlyAnchor) {
    return getNextNthDate(monthlyAnchor);
  }

  if (interval === PayoutInterval.WEEKLY && weeklyAnchor) {
    return getNextWeeklyPayoutDate(weeklyAnchor as DayOfWeek);
  }

  return getNext15thDate();
};

export const getNextWeeklyPayoutDate = (weeklyAnchor: DayOfWeek): Date => {
  const today = new Date();
  const todayIndex = today.getDay();
  const targetDayIndex = DAYS_OF_WEEK.indexOf(weeklyAnchor);

  const daysUntilNext = (targetDayIndex - todayIndex + 7) % 7 || 7;
  const nextPayDate = new Date();
  nextPayDate.setDate(today.getDate() + daysUntilNext);

  return nextPayDate;
};

export const formatPayoutDate = (
  payoutEpochTimestampInSeconds: number
): Date => {
  const date = new Date(0);
  date.setUTCSeconds(payoutEpochTimestampInSeconds);
  return date;
};

export const formatCurrencyFromBaseUnit = (
  amount: number,
  currency: string,
  options?: Intl.NumberFormatOptions
) => {
  const formattedAmount = (amount / 100).toFixed(2);
  const formattedCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    ...options,
  }).format(parseFloat(formattedAmount));
  return formattedCurrency;
};

export const formatCurrency = (
  amount: number,
  currency: string,
  options?: Intl.NumberFormatOptions
) => {
  const formattedCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    ...options,
  }).format(amount);
  return formattedCurrency;
};

export const getTimestampOfNMonthsAgo = (numberOfMonths: number) => {
  const modifiedDate = new Date();
  return modifiedDate.setMonth(modifiedDate.getMonth() - numberOfMonths);
};
