import Clarity from "@microsoft/clarity";
import { useEffect } from "react";

const CLARITY_PROJECT_ID = "m5sbcrmwcx";

export const useClarity = () => {
  useEffect(() => {
    Clarity.init(CLARITY_PROJECT_ID);
    Clarity.consent();
  }, []);
};
