import { VideocamOffRounded, VideocamRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { CreatorExercise } from "../../../../store/exercises/types";
import { MuscleGroupChip } from "../MuscleGroupChip";

interface Props {
  options: CreatorExercise[];
  isLoading: boolean;
  getNextPage: () => void;
  searchQuery: string;
  setSearchQuery: (value: React.SetStateAction<string>) => void;
  onSelectExercise: (value: CreatorExercise | null) => void;
}

const hasScrolledToTheEnd = (element: Element) =>
  element.scrollTop + element.clientHeight >= element.scrollHeight;

export const ExerciseAutocomplete: React.FC<Props> = ({
  options,
  isLoading,
  getNextPage,
  setSearchQuery,
  searchQuery,
  onSelectExercise,
}) => {
  return (
    <>
      <Autocomplete
        inputValue={searchQuery}
        onInputChange={(_, newInputValue) => setSearchQuery(newInputValue)}
        onChange={(_, newAutocompleteValue) => {
          onSelectExercise(newAutocompleteValue);
        }}
        blurOnSelect
        options={options}
        loading={isLoading}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options) => options}
        ListboxProps={{
          onScroll: (e) => {
            if (hasScrolledToTheEnd(e.currentTarget)) getNextPage();
          },
        }}
        renderOption={(props, option) => {
          return (
            <ListItem {...props}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems={"center"}
                  gap={2}
                  py={1}
                  sx={{ overflow: "hidden" }}
                >
                  {option.creatorVideos.length ? (
                    <VideocamRounded color="success" />
                  ) : (
                    <VideocamOffRounded color="error" />
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: "90%" }}
                  >
                    <Typography lineHeight={1}>{option.name}</Typography>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      color="textSecondary"
                      sx={{
                        fontSize: 12,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {option.creatorNotes.at(0)?.content || option.defaultNote}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <MuscleGroupChip muscleGroup={option.muscleGroup} />
                </Box>
              </Box>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label="Search exercises"
            placeholder="e.g. Alternating Arm Standing Dumbbell Bicep Curl"
            margin="dense"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
