import React from "react";
import {
  CreatorExercise,
  CreatorVideo,
} from "../../../../../store/exercises/types";
import { useExerciseVideoCard } from "./useExerciseVideoCard";
import { ExerciseVideoCard as Component } from "./ExerciseVideoCard";
import { ModalState } from "../../../../../hooks/useModalState";

export interface ExerciseVideoCardProps {
  video: CreatorVideo;
  exerciseName: string;
  exerciseId: number;
  onDelete: () => void;
  onSetDefault: () => void;
  disableDelete?: boolean;
  selectedExercise: CreatorExercise | null;
  setSelectedExercise: (exercise: CreatorExercise) => void;
  switchVideoExerciseSuccessToast: ModalState;
  switchVideoExerciseErrorToast: ModalState;
  handleSwitchVideoExercise: (videoId: number) => Promise<void>;
  isSwitchVideoExerciseLoading: boolean;
}

export const ExerciseVideoCard: React.FC<ExerciseVideoCardProps> = (props) => {
  const videoCardProps = useExerciseVideoCard(props);
  return <Component {...videoCardProps} />;
};
