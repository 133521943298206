import React from "react";
import { WalletExpressAccountPayout } from "../types";
import { Grid } from "@mui/material";
import {
  formatToShortDayAndMonth,
  formatToShortMonthAndYear,
} from "../../../../util/date";
import { ComparisonStatistic } from "./ComparisonStatistic";
import { formatCurrencyFromBaseUnit } from "./utils";
import { PayoutInterval } from "../../../../store/payouts/types";

interface Props {
  payouts: WalletExpressAccountPayout[];
  maxComparisons?: number;
  interval: PayoutInterval;
}
export const PayoutsComparison: React.FC<Props> = ({
  payouts,
  interval,
  maxComparisons = 3,
}) => {
  const renderComparisonStatistic = (
    interval: PayoutInterval,
    payout: WalletExpressAccountPayout,
    referencePayout: WalletExpressAccountPayout
  ) => {
    return (
      <Grid item xs={4}>
        <ComparisonStatistic
          value={{
            value: payout.amount,
            formattedValue: formatCurrencyFromBaseUnit(
              payout.amount,
              payout.currency
            ),
            label:
              interval === PayoutInterval.MONTHLY
                ? formatToShortMonthAndYear(payout.arrivalDate)
                : formatToShortDayAndMonth(payout.arrivalDate),
          }}
          referenceValue={{
            value: referencePayout.amount,
            formattedValue: formatCurrencyFromBaseUnit(
              referencePayout.amount,
              referencePayout.currency
            ),
            label:
              interval === PayoutInterval.MONTHLY
                ? formatToShortMonthAndYear(referencePayout.arrivalDate)
                : formatToShortDayAndMonth(referencePayout.arrivalDate),
          }}
        />
      </Grid>
    );
  };

  const renderEmptyComparisonStatistic = () => {
    return (
      <Grid item xs={4}>
        <ComparisonStatistic
          value={{
            value: 112344.78,
            formattedValue: formatCurrencyFromBaseUnit(0, "USD"),
            label: "Sept 2024",
          }}
          referenceValue={{
            value: 5000.78,
            formattedValue: formatCurrencyFromBaseUnit(0, "USD"),
            label: "Sept 2024",
          }}
          disabled
        />
      </Grid>
    );
  };

  if (payouts.length < 2) {
    return null;
  }

  return (
    <>
      {[...new Array(maxComparisons)].map((_, index) => {
        const currentPayout = payouts[index];
        const previousPayout = payouts[index + 1];
        if (!previousPayout || !currentPayout) {
          return (
            <React.Fragment key={index}>
              {renderEmptyComparisonStatistic()}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={index}>
            {renderComparisonStatistic(interval, currentPayout, previousPayout)}
          </React.Fragment>
        );
      })}
    </>
  );
};
