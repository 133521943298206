import { useCallback, useMemo, useReducer, useState } from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { useAutosave } from "../../../hooks/useAutosave";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useLeaveSiteConfirm } from "../../../hooks/useLeaveSiteConfirm";
import { CreatorExercise } from "../../../store/exercises/types";
import {
  WorkoutBuilderReducer,
  workoutBuilderActions,
} from "../../../store/workout-builder/slice";
import { defaultWorkoutBuilderState } from "../../../store/workout-builder/types";
import { ExerciseParameter, ExerciseSet } from "../../../store/workout/types";
import {
  DialogName,
  useConfirmDialog,
  useDialog,
} from "../../common/Dialog/hooks";
import { useExerciseAutocomplete } from "./ExerciseAutocomplete/useExerciseAutocomplete";
import { WorkoutBuilderProps } from "./types";
import {
  calculateIfWorkoutHasChanged,
  generateDefaultExerciseBlockWithExercise,
  generateEmptyWorkoutSection,
} from "./utils";

const SAVE_WORKOUT_TIMEOUT_MS = 5000;

export const useWorkoutBuilder = ({
  expandedWorkout,
  saveWorkout: saveWorkoutSections,
  updateDescription,
  updateName,
  isUpdateError,
  isUpdating,
  onPressBack,
  weekAndDay,
  workoutPlanName,
}: WorkoutBuilderProps) => {
  const [initialExpandedWorkout, setInitialExpandedWorkout] =
    useState(expandedWorkout);

  const [workoutBuilderState, dispatch] = useReducer(
    WorkoutBuilderReducer,
    undefined,
    () => ({
      ...defaultWorkoutBuilderState,
      workoutBuilder: {
        ...initialExpandedWorkout,
        sections: initialExpandedWorkout.sections.length
          ? initialExpandedWorkout.sections
          : [generateEmptyWorkoutSection()],
      },
    })
  );

  const currentExpandedWorkout = workoutBuilderState.workoutBuilder!;

  const [exerciseInsertIndex, setExerciseInsertIndex] = useState<number | null>(
    null
  );

  const hasWorkoutChanged = useMemo(() => {
    const hasChanged = calculateIfWorkoutHasChanged({
      initialExpandedWorkout,
      currentExpandedWorkout,
    });
    return hasChanged;
  }, [currentExpandedWorkout, initialExpandedWorkout]);

  const [
    isShowingWorkoutBuilderError,
    hideWorkoutBuilderError,
    showWorkoutBuilderError,
  ] = useErrorMessageState(false);

  const exerciseAutocomplete = useExerciseAutocomplete();

  const addNewExercise = (exercise: CreatorExercise) => {
    try {
      dispatch(
        workoutBuilderActions.appendExerciseBlock({
          block: generateDefaultExerciseBlockWithExercise(exercise),
          index: exerciseInsertIndex ?? undefined,
        })
      );
    } catch (e) {
      showWorkoutBuilderError();
    }
  };

  const deleteExerciseEntry = (blockIndex: number, entryIndex: number) => {
    dispatch(
      workoutBuilderActions.removeExerciseEntry({ blockIndex, entryIndex })
    );
  };

  const duplicateSetInEntry = (blockIndex: number, setIndex: number) => {
    dispatch(
      workoutBuilderActions.duplicateSetInAllExerciseEntrysInBlock({
        blockIndex,
        setIndex,
      })
    );
  };

  const updateSetInEntry = (
    blockIndex: number,
    entryIndex: number,
    set: ExerciseSet
  ) => {
    dispatch(
      workoutBuilderActions.updateSetInEntry({ blockIndex, entryIndex, set })
    );
  };

  const deleteSetInEntry = (blockIndex: number, setIndex: number) => {
    dispatch(
      workoutBuilderActions.removeSetInAllExerciseEntrysInBlock({
        blockIndex,
        setIndex,
      })
    );
  };

  const updateExerciseTypeOfEntry = (
    blockIndex: number,
    entryIndex: number,
    newParameter: ExerciseParameter
  ) => {
    dispatch(
      workoutBuilderActions.setParameterValueAtBlockAndEntryIndex({
        blockIndex,
        entryIndex,
        newParameter,
      })
    );
  };

  const updateNotesInEntry = (
    blockIndex: number,
    entryIndex: number,
    notes: string
  ) => {
    dispatch(
      workoutBuilderActions.updateNotesInEntry({
        blockIndex,
        entryIndex,
        notes,
      })
    );
  };

  const linkExerciseBlockWithNext = (blockIndex: number) => {
    dispatch(workoutBuilderActions.combineExerciseBlockWithNext(blockIndex));
  };

  const unlinkExerciseEntrysAtIndexForBlock = (
    blockIndex: number,
    entryIndex: number
  ) => {
    dispatch(
      workoutBuilderActions.splitExerciseEntrysAtIndexForBlock({
        blockIndex,
        entryIndex,
      })
    );
  };

  const updateVideoInExerciseEntry = (
    blockIndex: number,
    entryIndex: number,
    videoId: number | null
  ) => {
    dispatch(
      workoutBuilderActions.updateVideoInExerciseEntry({
        blockIndex,
        videoId,
        entryIndex,
      })
    );
  };

  const reorderExerciseBlock = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    dispatch(
      workoutBuilderActions.reorderExerciseBlock({
        sourceIndex,
        destinationIndex,
      })
    );
  };
  const reorderExerciseEntryInBlock = (
    blockId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    dispatch(
      workoutBuilderActions.reorderExerciseEntryInBlock({
        blockId,
        sourceIndex,
        destinationIndex,
      })
    );
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { destination, source, type } = result;
    if (!destination) return;

    if (destination.index === source.index) return;

    if (type === "block") {
      reorderExerciseBlock(source.index, destination.index);
      return;
    }
    if (type === "entry") {
      if (destination.droppableId !== source.droppableId) return;
      reorderExerciseEntryInBlock(
        destination.droppableId,
        source.index,
        destination.index
      );
      return;
    }
  };

  const saveWorkout = useCallback(async () => {
    const expandedWorkout = await saveWorkoutSections(
      currentExpandedWorkout.sections
    );
    if (expandedWorkout) {
      setInitialExpandedWorkout(expandedWorkout);
    }
  }, [currentExpandedWorkout.sections, saveWorkoutSections]);

  useAutosave(
    useCallback(async () => {
      if (!isUpdateError) await saveWorkout();
    }, [isUpdateError, saveWorkout]),
    SAVE_WORKOUT_TIMEOUT_MS,
    hasWorkoutChanged
  );

  const [isShowingUpdateErrorMessage, hideUpdateErrorMessage] =
    useErrorMessageState(isUpdateError);
  const saveWorkoutDialog = useConfirmDialog(
    DialogName.SAVE_WORKOUT,
    saveWorkout,
    { isLoading: isUpdating }
  );
  const [
    isLeaveBuilderDialogOpen,
    openLeaveBuilderDialog,
    closeLeaveBuilderDialog,
  ] = useDialog();

  useLeaveSiteConfirm(hasWorkoutChanged);

  const saveWorkoutAndWorkoutName = async (workoutName: string) => {
    if (hasWorkoutChanged) {
      await saveWorkout();
    }
    await updateName(workoutName);
  };

  const saveWorkoutAndWorkoutDescription = async (
    workoutDescription: string
  ) => {
    if (hasWorkoutChanged) {
      await saveWorkout();
    }
    await updateDescription(workoutDescription);
  };

  const saveWorkoutSectionsNameAndDescriptionThenNavigate = async (
    exercise: CreatorExercise
  ) => {
    if (hasWorkoutChanged) {
      try {
        await Promise.all([
          saveWorkoutSections(currentExpandedWorkout.sections),
          updateName(currentExpandedWorkout.name),
          updateDescription(currentExpandedWorkout.description),
        ]);
        window.open(
          `/exercises/${exercise.id}`,
          "_blank",
          "noopener noreferrer"
        );
      } catch (e) {
        showWorkoutBuilderError();
      }
    } else {
      window.open(`/exercises/${exercise.id}`, "_blank", "noopener noreferrer");
    }
  };

  return {
    saveWorkoutAndWorkoutName,
    saveWorkoutAndWorkoutDescription,
    isLoadingUpdateWorkout: isUpdating,
    isShowingUpdateErrorMessage,
    hideUpdateErrorMessage,
    addNewExercise,
    isShowingWorkoutBuilderError,
    hideWorkoutBuilderError,
    deleteExerciseEntry,
    duplicateSetInEntry,
    updateSetInEntry,
    deleteSetInEntry,
    updateExerciseTypeOfEntry,
    updateVideoInExerciseEntry,
    saveWorkout,
    linkExerciseBlockWithNext,
    unlinkExerciseEntrysAtIndexForBlock,
    onDragEnd,
    exerciseInsertIndex,
    setExerciseInsertIndex,
    saveWorkoutDialog,
    hasWorkoutChanged,
    expandedWorkout: currentExpandedWorkout,
    weekAndDay,
    workoutPlanName,
    onPressBack,
    updateNotesInEntry,
    isLeaveBuilderDialogOpen,
    openLeaveBuilderDialog,
    closeLeaveBuilderDialog,
    saveWorkoutSectionsNameAndDescriptionThenNavigate,
    ...exerciseAutocomplete,
  };
};
