import { Box } from "@mui/material";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { theme } from "../../../../theme/theme";
import {
  formatToShortMonthAndYear,
  startOfMonth,
  formatToShortDayAndMonth,
} from "../../../../util/date";
import { ChartTooltip } from "../../../common/Charts/ChartTooltip";
import { PayoutStatusChip } from "./PayoutStatusChip";
import { formatCurrencyFromBaseUnit } from "./utils";
import { WalletExpressAccountPayout } from "../types";
import { PayoutInterval } from "../../../../store/payouts/types";

interface Props {
  interval: PayoutInterval;
  payouts: WalletExpressAccountPayout[];
  currencyCode: string;
}

export const PayoutsLineChart: React.FC<Props> = ({
  payouts,
  currencyCode,
  interval,
}) => {
  return (
    <ResponsiveLine
      margin={{ top: 50, right: 50, bottom: 40, left: 100 }}
      xScale={{ type: "time", useUTC: false }}
      yScale={{
        type: "linear",
      }}
      defs={[
        linearGradientDef("main", [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0 },
        ]),
      ]}
      fill={[{ match: "*", id: "main" }]}
      curve="monotoneX"
      colors={Object.values(theme.palette.primary)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        format:
          interval === PayoutInterval.MONTHLY
            ? formatToShortMonthAndYear
            : formatToShortDayAndMonth,
        tickValues: payouts.map((payout) => startOfMonth(payout.arrivalDate)),
      }}
      data={[
        {
          id: "payouts",
          data: payouts.map((payout) => ({
            x: startOfMonth(payout.arrivalDate),
            y: payout.amount,
            ...payout,
          })),
        },
      ]}
      axisLeft={{
        tickSize: 5,
        tickPadding: 20,
        tickRotation: 0,
        tickValues: 6,
        ticksPosition: "before",
        legend: "Payout Amount",
        legendOffset: -80,
        legendPosition: "middle",
        format: (value) =>
          formatCurrencyFromBaseUnit(value, currencyCode, {
            notation: "compact",
          }),
      }}
      enableGridX={true}
      enableGridY={false}
      enableArea={true}
      pointSize={8}
      pointColor={theme.palette.primary.main}
      isInteractive={true}
      enableCrosshair={true}
      useMesh={true}
      tooltip={({ point }) => (
        <ChartTooltip
          xValue={
            interval === PayoutInterval.MONTHLY
              ? formatToShortMonthAndYear(point.data.x as Date)
              : formatToShortDayAndMonth(point.data.x as Date)
          }
          yValue={`${formatCurrencyFromBaseUnit(
            point.data.y as number,
            currencyCode
          )}`}
        >
          <Box pt={1}>
            <PayoutStatusChip status={(point.data as any).status} />
          </Box>
        </ChartTooltip>
      )}
    />
  );
};
