import { useCallback, useMemo, useState } from "react";
import { getMediaUrl } from "../../../../common/MuxPlayer/MuxPlayer.utils";
import { useObjectModalState } from "../../../../../hooks/useModalState";
import { useExerciseAutocomplete } from "../../../WorkoutBuilder/ExerciseAutocomplete/useExerciseAutocomplete";
import { ExerciseVideoCardProps } from ".";

export const useExerciseVideoCard = ({
  video,
  exerciseName,
  exerciseId,
  onDelete,
  onSetDefault,
  disableDelete = false,
  selectedExercise,
  setSelectedExercise,
  switchVideoExerciseSuccessToast,
  switchVideoExerciseErrorToast,
  handleSwitchVideoExercise,
  isSwitchVideoExerciseLoading,
}: ExerciseVideoCardProps) => {
  const { muxVideo } = video;
  const mediaUrl = getMediaUrl(video);

  const videoPlayerModal = useObjectModalState(false);
  const deleteConfirmDialog = useObjectModalState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const closeMenuWithCallback = (callback: () => void) => () => {
    closeMenu();
    callback();
  };
  const closeConfirmWithCallback = (callback: () => void) => () => {
    deleteConfirmDialog.hide();
    callback();
  };

  const switchExerciseDialog = useObjectModalState(false);
  const handleSwitchVideoExerciseSubmit = useCallback(async () => {
    handleSwitchVideoExercise(video.id);
    switchExerciseDialog.hide();
  }, [handleSwitchVideoExercise, switchExerciseDialog, video.id]);

  const exerciseAutocomplete = useExerciseAutocomplete();
  const filteredData = useMemo(
    () =>
      exerciseAutocomplete.exerciseSearchResultsResource.data?.filter(
        (item) => {
          return item.id !== exerciseId;
        }
      ),
    [exerciseAutocomplete.exerciseSearchResultsResource.data, exerciseId]
  );

  return {
    video,
    muxVideo,
    exerciseName,
    mediaUrl,
    videoPlayerModal,
    deleteConfirmDialog,
    anchorEl,
    isMenuOpen,
    openMenu,
    closeMenu,
    closeMenuWithCallback,
    closeConfirmWithCallback,
    onDelete,
    onSetDefault,
    disableDelete,

    handleSwitchVideoExerciseSubmit,
    isSwitchVideoExerciseLoading,
    switchExerciseDialog,
    selectedExercise,
    setSelectedExercise,
    switchVideoExerciseErrorToast,
    switchVideoExerciseSuccessToast,
    ...exerciseAutocomplete,
    exerciseSearchResultsResource: {
      ...exerciseAutocomplete.exerciseSearchResultsResource,
      data: filteredData,
    },
  };
};
