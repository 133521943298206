import {
  BalanceAvailability,
  BalanceAvailabilityAPI,
  ExpressAccount,
  ExpressAccountBalance,
  ExpressAccountBalanceAPI,
  ExpressAccountPayout,
  ExpressAccountPayoutAPI,
  ExpressAccountResponse,
  PayoutInformation,
  PayoutInformationAPI,
} from "./types";

export const transformExpressAccountFromAPI = (
  response: ExpressAccountResponse
): ExpressAccount => {
  const { express_account, payout_schedule } = response;
  return {
    id: express_account.id,
    chargesEnabled: express_account.charges_enabled,
    countryCode: express_account.country,
    detailsSubmitted: express_account.details_submitted,
    stripeAccountID: express_account.stripe_account_id,
    trainerProfileID: express_account.trainer_profile_id,
    transfersEnabled: express_account.transfers_enabled,
    payoutSchedule: {
      delayDays: payout_schedule.delay_days,
      interval: payout_schedule.interval,
      monthlyAnchor: payout_schedule.monthly_anchor,
      weeklyAnchor: payout_schedule.weekly_anchor,
    },
  };
};

export const transformBalanceAvailablilityFromAPI = (
  api: BalanceAvailabilityAPI
): BalanceAvailability => ({
  amount: api.amount,
  currency: api.currency,
});

export const transformExpressAccountBalanceFromAPI = (
  api: ExpressAccountBalanceAPI
): ExpressAccountBalance => ({
  available: api.available.map(transformBalanceAvailablilityFromAPI),
  pending: api.pending.map(transformBalanceAvailablilityFromAPI),
});

export const transformExpressAccountPayoutFromAPI = (
  api: ExpressAccountPayoutAPI
): ExpressAccountPayout => ({
  id: api.id,
  amount: api.amount,
  arrivalDate: api.arrival_date,
  status: api.status,
  currency: api.currency,
});

export const transformPayoutInformationFromAPI = (
  api: PayoutInformationAPI
): PayoutInformation => ({
  balance: transformExpressAccountBalanceFromAPI(api.balance),
  payouts: api.payouts.map(transformExpressAccountPayoutFromAPI),
});
