import { getUniformYouTubeLink } from "../../components/common/YoutubeEmbed/YouTubeEmbed.utils";
import {
  CreatorExercise,
  CreatorExerciseAPI,
  CreatorNote,
  CreatorNoteAPI,
  CreatorVideo,
  CreatorVideoAPI,
  MuxVideo,
  MuxVideoAPI,
} from "./types";

const transformCreatorNoteFromAPI = (api: CreatorNoteAPI): CreatorNote => {
  return {
    id: api.id,
    content: api.content,
    createdBy: api.created_by,
  };
};

const transformMuxVideoFromAPI = (api: MuxVideoAPI): MuxVideo => {
  return {
    title: api.title,
    videoToken: api.video_token,
    thumbnailToken: api.thumbnail_token,
    status: api.status,
    assetId: api.asset_id,
    playbackId: api.playback_id,
    playbackPolicy: api.playback_policy,
    duration: api.duration,
  };
};

const transformCreatorVideoFromAPI = (api: CreatorVideoAPI): CreatorVideo => {
  return {
    id: api.id,
    youtubeLink: api.youtube_link
      ? getUniformYouTubeLink(api.youtube_link)
      : null,
    createdBy: api.created_by,
    createdAt: api.created_at,
    isDefault: api.is_default_video,
    muxVideo: api.mux_video ? transformMuxVideoFromAPI(api.mux_video) : null,
  };
};

const transformCreatorNoteToAPI = (api: CreatorNote): CreatorNoteAPI => {
  return {
    id: api.id,
    content: api.content,
    created_by: api.createdBy,
  };
};

const transformMuxVideoToAPI = (api: MuxVideo): MuxVideoAPI => {
  return {
    title: api.title,
    video_token: api.videoToken,
    thumbnail_token: api.thumbnailToken,
    status: api.status,
    asset_id: api.assetId,
    playback_id: api.playbackId,
    playback_policy: api.playbackPolicy,
    duration: api.duration,
  };
};

const transformCreatorVideoToAPI = (api: CreatorVideo): CreatorVideoAPI => {
  return {
    id: api.id,
    youtube_link: api.youtubeLink,
    created_by: api.createdBy,
    created_at: api.createdAt,
    is_default_video: api.isDefault,
    mux_video: api.muxVideo ? transformMuxVideoToAPI(api.muxVideo) : null,
  };
};

export const transformCreatorExerciseFromAPI = (
  api: CreatorExerciseAPI
): CreatorExercise => {
  const creatorNotes = api.creator_notes.map(transformCreatorNoteFromAPI);
  const creatorVideos = api.creator_videos.map(transformCreatorVideoFromAPI);
  return {
    id: api.id,
    name: api.name,
    muscleGroup: api.muscle_group,
    defaultNote: api.default_note,
    creatorNotes,
    creatorVideos,
    isWeighted: api.weighted,
    isIsometric: api.isometric,
    isUnilateral: api.unilateral,
  };
};
export const transformCreatorExerciseToAPI = (
  api: CreatorExercise
): CreatorExerciseAPI => {
  const creator_notes = api.creatorNotes.map(transformCreatorNoteToAPI);
  const creator_videos = api.creatorVideos.map(transformCreatorVideoToAPI);
  return {
    id: api.id,
    name: api.name,
    muscle_group: api.muscleGroup,
    default_note: api.defaultNote,
    creator_notes,
    creator_videos,
    weighted: api.isWeighted,
    isometric: api.isIsometric,
    unilateral: api.isUnilateral,
  };
};

export const transformAllCreatorExercisesFromAPI = (
  api: CreatorExerciseAPI[]
): CreatorExercise[] => api.map(transformCreatorExerciseFromAPI);
