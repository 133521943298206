import { Delete, MoreVert } from "@mui/icons-material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { MuxVideoStatus } from "../../../../../store/exercises/types";
import { relativeDate } from "../../../../../util/date";
import { BaseConfirmDialog } from "../../../../common/Dialog/BaseConfirmDialog";
import { MuxPlayer } from "../../../../common/MuxPlayer";
import { YouTubeEmbed } from "../../../../common/YoutubeEmbed";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { useExerciseVideoCard } from "./useExerciseVideoCard";
import { ExerciseAutocomplete } from "../../../WorkoutBuilder/ExerciseAutocomplete/ExerciseAutocomplete";

interface Props extends ReturnType<typeof useExerciseVideoCard> {}

export const ExerciseVideoCard: React.FC<Props> = ({
  video,
  muxVideo,
  exerciseName,
  mediaUrl,
  videoPlayerModal,
  deleteConfirmDialog,
  anchorEl,
  isMenuOpen,
  openMenu,
  closeMenu,
  closeMenuWithCallback,
  closeConfirmWithCallback,
  onDelete,
  onSetDefault,
  disableDelete,
  switchExerciseDialog,

  setSelectedExercise,
  exerciseSearchResultsResource,
  getNextPage,
  searchQuery,
  setSearchQuery,
  handleSwitchVideoExerciseSubmit,
  isSwitchVideoExerciseLoading,
}) => {
  return (
    <>
      <Card sx={{ width: 345, height: "100%" }} elevation={1}>
        <CardActionArea onClick={videoPlayerModal.show}>
          <CardMedia
            sx={{ height: 180 }}
            image={
              muxVideo?.status === MuxVideoStatus.PROCESSING
                ? "/fallback.png"
                : mediaUrl
            }
          />
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                {video.isDefault && (
                  <Stack direction="row">
                    <Chip size="small" label="Default" icon={<CheckCircle />} />
                  </Stack>
                )}
                <Typography variant="body2" color="text.secondary">
                  {`Added ${
                    relativeDate(new Date(video.createdAt)) || "just now"
                  }`}
                </Typography>
              </Stack>
              <IconButton
                aria-label="video-menu-button"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openMenu(e);
                }}
              >
                <MoreVert />
              </IconButton>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={videoPlayerModal.isVisible}
        fullWidth
        maxWidth="md"
        onClose={videoPlayerModal.hide}
      >
        <DialogTitle>{exerciseName}</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            {muxVideo && muxVideo.status !== MuxVideoStatus.READY && (
              <Alert severity="info">
                This video is still processing. It should be ready soon.
              </Alert>
            )}
            <Typography variant="body2" color="text.secondary">
              {`Added ${new Date(video.createdAt).toLocaleDateString()}`}
            </Typography>
            {muxVideo && (
              <MuxPlayer
                playbackId={muxVideo.playbackId}
                metadata={{ videoTitle: muxVideo.title }}
                tokens={{
                  playback: muxVideo.videoToken,
                  thumbnail: muxVideo.thumbnailToken,
                }}
              />
            )}
            {!muxVideo && video.youtubeLink && (
              <YouTubeEmbed videoLink={video.youtubeLink} />
            )}
            <Typography variant="body2" color="text.secondary">
              {video.muxVideo?.title || video.youtubeLink}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={videoPlayerModal.hide}
            color="neutral"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={deleteConfirmDialog.show}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="video-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{ "aria-labelledby": "video-menu-button" }}
      >
        <MenuItem onClick={closeMenuWithCallback(switchExerciseDialog.show)}>
          <ListItemIcon>
            <ShuffleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Switch Exercise</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={closeMenuWithCallback(deleteConfirmDialog.show)}
          disabled={disableDelete}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={closeMenuWithCallback(onSetDefault)}
          disabled={video.isDefault}
        >
          <ListItemIcon>
            <CheckCircle fontSize="small" />
          </ListItemIcon>
          <ListItemText>Set Default</ListItemText>
        </MenuItem>
      </Menu>

      <BaseConfirmDialog
        title={"Delete video"}
        isOpen={deleteConfirmDialog.isVisible}
        onClose={deleteConfirmDialog.hide}
        confirmButtonProps={{ color: "error" }}
        confirmText="Delete"
        onClickConfirm={closeConfirmWithCallback(onDelete)}
        onClickCancel={deleteConfirmDialog.hide}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
      </BaseConfirmDialog>

      <BaseConfirmDialog
        title="Select an exercise to move this video to"
        isOpen={switchExerciseDialog.isVisible}
        onClose={switchExerciseDialog.hide}
        confirmText="Move Video"
        onClickConfirm={handleSwitchVideoExerciseSubmit}
        onClickCancel={switchExerciseDialog.hide}
        isLoading={isSwitchVideoExerciseLoading}
      >
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Start typing an exercise name below
          </DialogContentText>

          <ExerciseAutocomplete
            options={exerciseSearchResultsResource.data || []}
            isLoading={exerciseSearchResultsResource.isLoading}
            getNextPage={getNextPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onSelectExercise={(exercise) => {
              exercise && setSelectedExercise(exercise);
            }}
          />
        </DialogContent>
      </BaseConfirmDialog>
    </>
  );
};
