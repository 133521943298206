import {
  Add,
  KeyboardBackspace,
  SubscriptionsRounded,
  WarningRounded,
} from "@mui/icons-material";
import {
  alertClasses,
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { EmptyList } from "../../common/EmptyList";
import { InlineInputEdit } from "../../common/InlineInputEdit";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { MuscleGroupChip } from "../WorkoutBuilder/MuscleGroupChip";
import { CreatorExerciseLoading } from "./CreatorExerciseLoading";
import { MuxAddVideoDialogForm } from "./MuxAddVideoDialogForm";
import { ExerciseVideoCard } from "./items/ExerciseVideoCard";
import { useCreatorExercisePage } from "./useCreatorExercisePage";

interface CreatorExercisePageProps
  extends ReturnType<typeof useCreatorExercisePage> {}

export const CreatorExercisePage: React.FC<CreatorExercisePageProps> = ({
  exercise,
  updateCreatorExerciseNote,
  notes,
  navigateBack,
  closeAddVideo,
  isAddVideoDialogOpen,
  openAddVideo,
  submitCreateExerciseVideo,
  isSubmittingNewVideo,
  deleteExerciseVideo,
  setVideoAsDefault,
  isPageError,
  isPageLoading,
  videoUploadProgress,
  isSubmittingNewVideoError,
  clearUploadVideoError,
  switchVideoExerciseSuccessToast,
  switchVideoExerciseErrorToast,
  selectedExercise,
  setSelectedExercise,
  navigateToTargetExercise,
  handleSwitchVideoExercise,
  isSwitchVideoExerciseLoading,
}) => {
  if (!exercise) {
    return <CreatorExerciseLoading />;
  }
  if (isPageLoading) {
    return <CreatorExerciseLoading />;
  }
  return (
    <>
      <PageTemplate>
        <Stack gap={1}>
          <Box mb={2}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={navigateBack}>
                <KeyboardBackspace fontSize="small" />
              </IconButton>
              <Typography variant="body2" color="GrayText">
                Back
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <PageHeader title={exercise.name} />
              <MuscleGroupChip muscleGroup={exercise.muscleGroup} />
            </Box>
          </Box>
          {isPageError ? (
            <EmptyList
              onClickButton={openAddVideo}
              primaryText={"New Exercise"}
              secondaryText={`Looks like you haven't used '${exercise.name}' in a workout yet. You can still add a video for it though!`}
              icon={<WarningRounded />}
              buttonText={"Yes, add video"}
              buttonIcon={<Add />}
            />
          ) : (
            <>
              <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">{`Videos (${exercise.creatorVideos.length})`}</Typography>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={openAddVideo}
                  >
                    Add Video
                  </Button>
                </Stack>
                {exercise.creatorVideos.length ? (
                  <Stack direction="row" gap={2} overflow="scroll" py={1}>
                    {exercise.creatorVideos.map((video, index, videos) => (
                      <React.Fragment key={video.id}>
                        <Box>
                          <ExerciseVideoCard
                            video={video}
                            exerciseName={exercise.name}
                            exerciseId={exercise.id}
                            onDelete={() => deleteExerciseVideo(video.id)}
                            onSetDefault={() => setVideoAsDefault(video.id)}
                            disableDelete={videos.length === 1}
                            selectedExercise={selectedExercise}
                            setSelectedExercise={setSelectedExercise}
                            switchVideoExerciseSuccessToast={
                              switchVideoExerciseSuccessToast
                            }
                            switchVideoExerciseErrorToast={
                              switchVideoExerciseErrorToast
                            }
                            handleSwitchVideoExercise={
                              handleSwitchVideoExercise
                            }
                            isSwitchVideoExerciseLoading={
                              isSwitchVideoExerciseLoading
                            }
                          />
                        </Box>
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <EmptyList
                    onClickButton={openAddVideo}
                    primaryText={"No videos"}
                    secondaryText={
                      "You haven't uploaded any videos for this exercise yet."
                    }
                    buttonText={"Add video"}
                    icon={<SubscriptionsRounded />}
                  />
                )}
              </Stack>
              <Box
                sx={{ display: "grid", gridTemplateColumns: "1fr 5fr", gap: 2 }}
              >
                <Typography sx={{ py: 1 }} fontWeight={600}>
                  Default Note
                </Typography>
                <InlineInputEdit
                  variant="body1"
                  initialValue={
                    exercise.creatorNotes.at(0)?.content ||
                    exercise.defaultNote ||
                    ""
                  }
                  placeholderText="Add default instruction here"
                  typographyProps={{ whiteSpace: "pre-line" }}
                  textFieldProps={{
                    multiline: true,
                    label: "Enter Instruction",
                  }}
                  isInputLoading={notes.isLoading}
                  handleSubmitText={updateCreatorExerciseNote}
                  vertical
                />
              </Box>
            </>
          )}
        </Stack>
      </PageTemplate>
      <Dialog
        open={isAddVideoDialogOpen}
        onClose={isSubmittingNewVideo ? undefined : closeAddVideo}
        fullWidth
        maxWidth="md"
      >
        <MuxAddVideoDialogForm
          isSubmitting={isSubmittingNewVideo}
          progress={videoUploadProgress}
          close={closeAddVideo}
          exerciseName={exercise.name}
          onSubmit={submitCreateExerciseVideo}
        />
      </Dialog>
      <SnackbarAlert
        isOpen={isSubmittingNewVideoError}
        close={clearUploadVideoError}
        message="There was an error uploading the video. Please try again."
        severity="error"
      />

      <SnackbarAlert
        isOpen={switchVideoExerciseErrorToast.isVisible}
        close={switchVideoExerciseErrorToast.hide}
        message="There was an switching the video's exercise. Please try again."
        severity="error"
      />
      <SnackbarAlert
        isOpen={switchVideoExerciseSuccessToast.isVisible}
        close={switchVideoExerciseSuccessToast.hide}
        message={`Successfully switched the video to ${selectedExercise?.name}`}
        severity="success"
        sx={{
          width: "max-content",
          [`.${alertClasses.message}`]: {
            maxWidth: "50vw",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
          [`.${alertClasses.action}`]: {
            padding: "2px 16px",
            paddingRight: 0,
          },
        }}
        action={
          navigateToTargetExercise && (
            <Button
              size="small"
              color="inherit"
              onClick={navigateToTargetExercise}
            >
              VIEW
            </Button>
          )
        }
      />
    </>
  );
};
