import { useState } from "react";

export const useModalState = (initialValue = false) => {
  const [isVisible, setIsVisible] = useState(initialValue);
  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  return [isVisible, show, hide] as const;
};

export interface ModalState {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
}

export const useObjectModalState = (initialValue = false) => {
  const [isVisible, show, hide] = useModalState(initialValue);
  return { isVisible, show, hide } as ModalState;
};
