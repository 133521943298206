import { CreatorExercise } from "../../../store/exercises/types";
import {
  ExerciseBlock,
  ExerciseEntry,
  ExerciseParameter,
  ExerciseParameterValue,
  ExerciseSet,
  ExpandedWorkout,
  WorkoutSection,
} from "../../../store/workout/types";
import { randomUUID } from "../../../util/uuid";
import {
  REPS_EXERCISE_PARAMETER,
  REST_EXERCISE_PARAMETER,
  TIME_EXERCISE_PARAMETER,
  createDefaultRepsParameterValue,
  createDefaultRestParameterValue,
} from "./fixtures";
import {
  NoIdExerciseBlock,
  NoIdExerciseEntry,
  NoIdExerciseParameterValue,
  NoIdExerciseSet,
  NoIdWorkoutSection,
} from "./types";

const removeRestFromExerciseEntry = (entry: ExerciseEntry): ExerciseEntry => {
  return {
    ...entry,
    sets: entry.sets.map((set) => ({
      ...set,
      values: set.values.filter(
        (value) => value.parameter.id !== REST_EXERCISE_PARAMETER.id
      ),
    })),
  };
};

export const createSupersetBlock = (entrys: ExerciseEntry[]) => {
  const entrysWithRestRemoved = entrys.map((entry, index, entrys) => {
    if (index === entrys.length - 1) {
      return entry;
    }
    return removeRestFromExerciseEntry(entry);
  });

  return generateExerciseBlockWithEntrys(entrysWithRestRemoved);
};

export const generateEmptyWorkoutSection = (): WorkoutSection => ({
  id: randomUUID(),
  blocks: [],
});

export const generateExerciseBlockWithEntrys = (
  exerciseEntrys: ExerciseEntry[]
): ExerciseBlock => {
  return {
    id: randomUUID(),
    entrys: exerciseEntrys,
  };
};
export const generateDefaultExerciseBlockWithExercise = (
  exercise: CreatorExercise
) => {
  const createExerciseEntry = () => ({
    id: randomUUID(),
    exercise,
    notes: "",
    videoId: null,
    sets: [
      {
        id: randomUUID(),
        values: [
          createDefaultRepsParameterValue(),
          createDefaultRestParameterValue(),
        ],
      },
    ],
  });

  const entries = exercise.isUnilateral
    ? [createExerciseEntry(), createExerciseEntry()]
    : [createExerciseEntry()];

  return generateExerciseBlockWithEntrys(entries);
};

export const getExerciseParameterFromExerciseEntry = (
  exerciseEntry: ExerciseEntry
): ExerciseParameter => {
  const exerciseEntryParameters = exerciseEntry.sets
    .map((set) => set.values.map((value) => value.parameter))
    .flat();
  const exerciseEntryParameterNames = exerciseEntryParameters.map(
    ({ name }) => name
  );
  const parameterNamesArray = Array.from(new Set(exerciseEntryParameterNames));

  return parameterNamesArray.includes(REPS_EXERCISE_PARAMETER.name)
    ? REPS_EXERCISE_PARAMETER
    : TIME_EXERCISE_PARAMETER;
};

const stripIdFromValue = ({
  parameter,
  value,
}: ExerciseParameterValue): NoIdExerciseParameterValue => ({
  parameter,
  value,
});

const stripIdFromSet = (set: ExerciseSet): NoIdExerciseSet => ({
  values: set.values.map(stripIdFromValue),
});

const stripIdFromEntry = ({
  exercise,
  sets,
  notes,
  videoId,
}: ExerciseEntry): NoIdExerciseEntry => ({
  exercise: {
    id: exercise.id,
    name: exercise.name,
    muscleGroup: exercise.muscleGroup,
  },
  sets: sets.map(stripIdFromSet),
  videoId,
  notes: notes === "" ? exercise.defaultNote || "" : notes,
});

const stripIdFromBlock = (block: ExerciseBlock): NoIdExerciseBlock => ({
  entrys: block.entrys.map(stripIdFromEntry),
});
const stripIdFromSection = (section: WorkoutSection): NoIdWorkoutSection => ({
  blocks: section.blocks.map(stripIdFromBlock),
});

export const stripIdsFromWorkout = (
  sections: WorkoutSection[]
): NoIdWorkoutSection[] => sections.map(stripIdFromSection);

export const isBlockSuperset = (block: ExerciseBlock | NoIdExerciseBlock) => {
  return block.entrys.length > 1;
};

export const getEntryNotes = (entry: ExerciseEntry): string => {
  const entrySpecificNotes = entry.notes;
  const exerciseSpecificInstruction = entry.exercise.defaultNote;
  if (entrySpecificNotes !== "") {
    return entrySpecificNotes;
  }
  if (
    exerciseSpecificInstruction !== null &&
    exerciseSpecificInstruction !== ""
  ) {
    return exerciseSpecificInstruction;
  }
  return "";
};

export const getRestValuesFromEntry = (entry: ExerciseEntry) => {
  return entry.sets.flatMap(
    (set) =>
      set.values.find(
        (value) => value.parameter.id === REST_EXERCISE_PARAMETER.id
      )!
  );
};

export const removeRestValuesFromEntry = (entry: ExerciseEntry) => {
  const removedValues: ExerciseParameterValue[] = [];

  entry.sets = entry.sets.map((set) => {
    set.values = set.values.filter((value) => {
      if (value.parameter.id === REST_EXERCISE_PARAMETER.id) {
        removedValues.push(value);
        return false;
      }
      return true;
    });
    return set;
  });

  return removedValues;
};

interface CalculateIfWorkoutHasChangedArgs {
  initialExpandedWorkout: ExpandedWorkout;
  currentExpandedWorkout: ExpandedWorkout;
}

export const calculateIfWorkoutHasChanged = ({
  initialExpandedWorkout,
  currentExpandedWorkout,
}: CalculateIfWorkoutHasChangedArgs) => {
  const initial = stripIdsFromWorkout(initialExpandedWorkout.sections);
  const current = stripIdsFromWorkout(currentExpandedWorkout.sections);

  return JSON.stringify(initial) !== JSON.stringify(current);
};
