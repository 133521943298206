import { createDefaultResource, Resource } from "../types";

export interface ExpressAccountResponse {
  express_account: ExpressAccountAPI;
  payout_schedule: PayoutScheduleAPI;
}

export interface BalanceAvailabilityAPI {
  amount: number;
  currency: string;
}

export interface ExpressAccountBalanceAPI {
  available: BalanceAvailabilityAPI[];
  pending: BalanceAvailabilityAPI[];
}

export interface BalanceAvailability {
  amount: number;
  currency: string;
}

export interface ExpressAccountBalance {
  available: BalanceAvailability[];
  pending: BalanceAvailability[];
}

export type StripePayoutStatus =
  | "paid"
  | "pending"
  | "in_transit"
  | "canceled"
  | "failed";

export interface ExpressAccountPayout {
  id: string;
  amount: number;
  arrivalDate: number;
  status: StripePayoutStatus;
  currency: string;
}

export interface ExpressAccountPayoutAPI {
  id: string;
  amount: number;
  arrival_date: number;
  status: StripePayoutStatus;
  currency: string;
}

export interface ExpressAccountAPI {
  id: string;
  trainer_profile_id: number;
  stripe_account_id: string;
  charges_enabled: boolean;
  transfers_enabled: boolean;
  details_submitted: boolean;
  country: string;
}

export enum PayoutInterval {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export interface PayoutScheduleAPI {
  delay_days: number;
  interval: PayoutInterval;
  monthly_anchor: number | null;
  weekly_anchor: string | null;
}

export interface ExpressAccount {
  id: string;
  trainerProfileID: number;
  stripeAccountID: string;
  chargesEnabled: boolean;
  transfersEnabled: boolean;
  detailsSubmitted: boolean;
  countryCode: string;
  payoutSchedule: PayoutSchedule;
}

export interface PayoutSchedule {
  delayDays: number;
  interval: PayoutInterval;
  monthlyAnchor: number | null;
  weeklyAnchor: string | null;
}

export interface PayoutInformationAPI {
  balance: ExpressAccountBalanceAPI;
  payouts: ExpressAccountPayoutAPI[];
}

export interface PayoutInformation {
  balance: ExpressAccountBalance;
  payouts: ExpressAccountPayout[];
}
export interface PayoutsState {
  expressAccount: Resource<ExpressAccount>;
  payoutInformation: Resource<PayoutInformation>;
}

export const defaultPayoutsState: PayoutsState = {
  expressAccount: createDefaultResource(),
  payoutInformation: createDefaultResource(),
};
