import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest } from "../../../hooks/request/useRequest";
import { useStore } from "../../../store";
import {
  DeleteExerciseVideo,
  GetCreatorExercise,
  SetVideoExercise,
  UpdateCreatorExerciseNote,
} from "../../../store/exercises/actions";
import {
  selectCurrentCreatorExercise,
  selectUpdateCreatorExerciseNote,
} from "../../../store/exercises/selectors";
import {
  CreateExerciseVideoUploadLinkAPI,
  CreatorExercise,
} from "../../../store/exercises/types";
import { useHandleUpChunk } from "./useHandleUpChunk";
import { useObjectModalState } from "../../../hooks/useModalState";

export interface SwitchVideoExerciseParams {
  videoId: number;
  exerciseId: number;
}

export const useCreatorExercisePage = () => {
  const [select, dispatch] = useStore();
  const { id: creatorExerciseIdString } = useParams();
  const creatorExerciseId = parseInt(creatorExerciseIdString!);
  const [uploadVideoLoading, setUploadVideoLoading] = useState(false);
  const [uploadVideoError, setUploadVideoError] = useState(false);

  const {
    isInProgress: isUploadChunkInProgress,
    progress,
    handleUpload,
  } = useHandleUpChunk();

  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);

  const fetchCreatorExerciseData = useCallback(async () => {
    creatorExerciseId && GetCreatorExercise(dispatch, creatorExerciseId);
  }, [creatorExerciseId, dispatch]);

  const { isError, isLoading, data } = select(selectCurrentCreatorExercise);
  const updateCreatorNote = select(selectUpdateCreatorExerciseNote);

  const [isAddVideoDialogOpen, setIsAddVideoDialogOpen] = useState(false);
  const openAddVideo = () => setIsAddVideoDialogOpen(true);
  const closeAddVideo = () => setIsAddVideoDialogOpen(false);

  const [getUploadVideoLink] = useRequest<CreateExerciseVideoUploadLinkAPI>(
    "GET",
    "/media/video-upload-link/"
  );

  const [postUploadVideoEntry] = useRequest("POST", "/builder/exercise/video/");

  const submitCreateExerciseVideo = useCallback(
    async (video: File) => {
      setUploadVideoError(false);
      setUploadVideoLoading(true);

      try {
        const uploadVideoRes = await getUploadVideoLink();

        if (!uploadVideoRes) {
          setUploadVideoError(true);
          setUploadVideoLoading(false);
          closeAddVideo();
          return;
        }

        handleUpload(
          video,
          uploadVideoRes.url,
          async () => {
            setUploadVideoLoading(false);
            await postUploadVideoEntry(undefined, {
              data: {
                upload_id: uploadVideoRes.id,
                title: video.name,
              },
              params: { exercise_id: creatorExerciseId },
            });
            closeAddVideo();
            fetchCreatorExerciseData();
          },
          () => {
            setUploadVideoError(true);
            setUploadVideoLoading(false);
            closeAddVideo();
          }
        );
      } catch (error) {
        setUploadVideoError(true);
        setUploadVideoLoading(false);
        closeAddVideo();
      }
    },
    [
      creatorExerciseId,
      fetchCreatorExerciseData,
      getUploadVideoLink,
      handleUpload,
      postUploadVideoEntry,
    ]
  );

  const clearUploadVideoError = () => setUploadVideoError(false);

  const deleteExerciseVideo = async (videoId: number) => {
    const promise = DeleteExerciseVideo(dispatch, videoId);
    promise.then(() => fetchCreatorExerciseData());
    return promise;
  };

  const [SetVideoAsDefault] = useRequest(
    "post",
    "/builder/exercise/default-video/"
  );

  const setVideoAsDefault = async (videoId: number) => {
    const promise = SetVideoAsDefault(undefined, {
      data: {
        video_id: videoId,
        exercise_id: creatorExerciseId,
      },
    });
    promise.then(() => fetchCreatorExerciseData());
  };

  const updateCreatorExerciseNote = async (newNote: string) => {
    creatorExerciseId &&
      (await UpdateCreatorExerciseNote(dispatch, creatorExerciseId, newNote));
  };

  useEffect(() => {
    fetchCreatorExerciseData();
  }, [fetchCreatorExerciseData]);

  const switchVideoExerciseSuccessToast = useObjectModalState(false);
  const switchVideoExerciseErrorToast = useObjectModalState(false);
  const [selectedExercise, setSelectedExercise] =
    useState<CreatorExercise | null>(null);

  const navigateToTargetExercise = useCallback(() => {
    if (selectedExercise) {
      switchVideoExerciseSuccessToast.hide();
      switchVideoExerciseErrorToast.hide();
      navigate(`/exercises/${selectedExercise.id}`);
    }
  }, [
    navigate,
    selectedExercise,
    switchVideoExerciseErrorToast,
    switchVideoExerciseSuccessToast,
  ]);

  const [isSwitchVideoExerciseLoading, setIsSwitchVideoExerciseLoading] =
    useState(false);

  const handleSwitchVideoExercise = useCallback(
    async (videoId: number) => {
      if (selectedExercise && videoId) {
        try {
          setIsSwitchVideoExerciseLoading(true);
          await SetVideoExercise(dispatch, selectedExercise.id, videoId);
          switchVideoExerciseSuccessToast.show();
        } catch {
          setIsSwitchVideoExerciseLoading(false);
          switchVideoExerciseErrorToast.show();
        }
      }
    },
    [
      dispatch,
      selectedExercise,
      switchVideoExerciseErrorToast,
      switchVideoExerciseSuccessToast,
    ]
  );

  return {
    isPageLoading: (!data && !isError) || isLoading,
    isPageError: isError,
    exercise: data,
    updateCreatorExerciseNote,
    refresh: fetchCreatorExerciseData,
    notes: updateCreatorNote,
    navigateBack,
    openAddVideo,
    closeAddVideo,
    isAddVideoDialogOpen,
    submitCreateExerciseVideo,
    isSubmittingNewVideo: isUploadChunkInProgress || uploadVideoLoading,
    isSubmittingNewVideoError: uploadVideoError,
    deleteExerciseVideo,
    setVideoAsDefault,
    videoUploadProgress: progress,
    clearUploadVideoError,
    selectedExercise,
    setSelectedExercise,
    switchVideoExerciseSuccessToast,
    switchVideoExerciseErrorToast,
    navigateToTargetExercise,
    handleSwitchVideoExercise,
    isSwitchVideoExerciseLoading,
  };
};
